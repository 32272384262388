import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";

import { BiLogoFacebookCircle } from "react-icons/bi";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
// import { MdKeyboardArrowDown } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";

import { useLocation } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import axios from "axios";
import { backend_url } from "../App";

import Translate from "./Translate";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [menu, setMenu] = useState("");
  const [categories, setCategories] = useState([]);
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleMenu = (menu) => {
    setExpandedMenu(expandedMenu === menu ? null : menu);
  };

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setShowMenu(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array to ensure the effect runs only once

  // console.log(pathname);

  useEffect(() => {
    getAllCategories();
  }, []);

  const getAllCategories = () => {
    const options = {
      method: "GET",
      url: `${backend_url}/category/get-all-category`,
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data);
        setCategories(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      <div className="flex justify-between w-[90%] m-auto items-center">
        <Link to="/" className="">
          <div className="">
            <img
              src="/operose-removebg-preview.png"
              className="attachment-large md:size-large wp-image-7621 sm:w-[70px] w-[50px] object-fill mt-2"
              alt=""
            ></img>
          </div>
        </Link>
        <div className="">
          <div className="flex justify-center items-center gap-2 mt-4">
            <div className="">
              <Translate />
            </div>
            <div className=" flex justify-center items-center mr-2 text-md md:text-xl">
              <FaWhatsapp className="text-[#73b03c]  sm:text-xl md:text-2xl mr-1 hidden sm:block" />
              <a
                href="https://api.whatsapp.com/send?phone=919893600060&text=Hi!%20I'm%20want%20more%20information%20about%20import%20and%20export%20trade"
                target="blanck"
              >
                <p className="font-semibold mr-3 text-sm  md:text-xl hidden sm:block">
                  +91 9039100060
                </p>
              </a>
            </div>

            <div className="text-xl sm:text-3xl">
              <a href="https://www.facebook.com" target="blanck">
                <BiLogoFacebookCircle className="" />
              </a>
            </div>
            <div className="text-xl sm:text-3xl">
              <a href="https://twitter.com" target="blanck">
                <AiOutlineTwitter />
              </a>
            </div>
            <div className="text-xl sm:text-3xl">
              <a href="https://www.linkedin.com" target="blanck">
                <AiFillLinkedin className="" />
              </a>
            </div>
            <div className="text-xl sm:text-3xl">
              <a href="https://www.youtube.com" target="blanck">
                <AiFillYoutube className="" />
              </a>
            </div>
            <div className="text-xl sm:text-3xl">
              <a href="https://www.instagram.com" target="blanck">
                <AiFillInstagram />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-[#447604]">
        <div className="w-[98%] xl:w-[95%] mt-2 bg-[#447604] lg:h-14 lg:flex lg:justify-center lg:items-center m-auto">
          <div className="ml-5 xl:ml-2 hidden w-[90%] lg:flex lg:justify-evenly lg:items-center text-sm xl:text-base gap-1">
            <Link
              to="/"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              }`}
            >
              <b>Home</b>
            </Link>

            <div className="relative">
              <Link
                to="#"
                className={` flex justify-center items-center py-1 pl-2  text-white hover:text-[#6cc551] h-11 ${
                  pathname === "/about" &&
                  "h-11 flex justify-center items-center"
                } `}
                onMouseEnter={() => setMenu("About")}
                onMouseLeave={() => setMenu("")}
              >
                <b>About Us</b> <RiArrowDropDownLine className="text-3xl" />
              </Link>

              {menu === "About" && (
                <div
                  className="flex flex-col gap-4 absolute bg-[#011936] z-10 w-[130px] rounded-md"
                  onMouseEnter={() => setMenu("About")}
                  onMouseLeave={() => setMenu("")}
                >
                  <ul>
                    <Link to="/about">
                      <li className=" text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                        About Operose
                      </li>
                    </Link>
                    <Link to="/people">
                      <li className="text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                        {" "}
                        People
                      </li>
                    </Link>
                    <Link to="/why">
                      <li className="text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                        Why
                      </li>
                    </Link>
                  </ul>
                </div>
              )}
            </div>

            <div className="relative">
              <Link
                to="/Products"
                className={` flex justify-center items-center py-1 pl-2 text-white hover:text-[#6cc551] text-center h-11 ${
                  pathname === "/products" && " text-[#6cc551] "
                } `}
                onMouseEnter={() => setMenu("Products")}
                onMouseLeave={() => setMenu("")}
              >
                <b>Categories</b>
                <RiArrowDropDownLine className="text-3xl" />
              </Link>
              {menu === "Products" && (
                <div
                  className="flex flex-col absolute bg-[#011936] z-10 w-[134px] rounded-md"
                  onMouseEnter={() => setMenu("Products")}
                  onMouseLeave={() => setMenu("")}
                >
                  {categories.map((index) => (
                    <div key={index.id}>
                      <Link to={`/product/${index.category_name}`}>
                        <h1 className=" text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                          {index.category_name}
                        </h1>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="relative">
              <Link
                to="#"
                className={` flex justify-center items-center py-1 pl-2 text-white hover:text-[#6cc551] h-11 ${
                  pathname === "/sales" && " flex justify-center items-center "
                } `}
                onMouseEnter={() => setMenu("sales")}
                onMouseLeave={() => setMenu("")}
              >
                <b>Sales Support</b>
                <RiArrowDropDownLine className="text-3xl" />
              </Link>
              {menu === "sales" && (
                <div
                  className="flex flex-col gap-4 absolute bg-[#011936] z-10 w-[160px] rounded-md"
                  onMouseEnter={() => setMenu("sales")}
                  onMouseLeave={() => setMenu("")}
                >
                  <ul className="">
                    <Link to="/business-query">
                      <li
                        className={` flex  pl-2 items-center font-semibold text-white hover:text-[#6cc551] h-11 ${
                          pathname === "/business-query" &&
                          " flex justify-center items-center   text-white"
                        } `}
                        onMouseEnter={() => setMenu("sales")}
                        onMouseLeave={() => setMenu("")}
                      >
                        {" "}
                        Business Query{" "}
                      </li>
                    </Link>
                  </ul>
                </div>
              )}
            </div>

            <Link
              to="/gallery"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/gallery"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              } `}
            >
              <b>Gallery</b>
            </Link>

            {/* <Link
              to="/people"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/people"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              } `}
            >
              <b>People</b>
            </Link> */}

            <Link
              to="/Updatess"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/Updatess"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              } `}
            >
              <b>Updates</b>
            </Link>

            <Link
              to="/Catalogues"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/Catalogues"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              } `}
            >
              <b>Catalogue</b>
            </Link>

            <Link
              to="/faq"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/faq"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              } `}
            >
              <b>FAQ's</b>
            </Link>

            <Link
              to="/contact"
              className={` flex justify-center items-center py-1 px-4  hover:text-[#6cc551] h-11 ${
                pathname === "/contact"
                  ? "h-11 flex justify-center items-center text-[#6cc551] "
                  : "text-white"
              }`}
            >
              <b>Contact Us</b>
            </Link>
          </div>
          <button
            className="lg:hidden text-3xl m-2 text-white"
            onClick={() => setShowMenu(!showMenu)}
          >
            {showMenu ? <IoMdClose /> : <GiHamburgerMenu />}
          </button>
        </div>
      </div>

      {showMenu && (
        <div className=" bg-[#447604] flex-row  lg:w-[20%] fixed lg:hidden z-10 ">
          <div className=" gap-2">
            <Link
              to="/"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={`flex justify-start items-center py-1  pl-2 text-white  hover:text-[#6cc551] h-11 ${
                pathname === "/" &&
                "h-11 flex justify-start items-start text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">HOME</b>
            </Link>

            <div className="relative ">
              <Link
                to="#"
                className={`flex justify-start items-center py-1 pl-2  text-white  hover:text-[#6cc551] h-11 ${
                  pathname === "/about" &&
                  "h-11 flex  items-start  text-[#6cc551]"
                }`}
                onClick={() => toggleMenu("about")}
              >
                <b className="text-[14px]">ABOUT US</b>
                <RiArrowDropDownLine className="text-3xl " />
              </Link>

              {expandedMenu === "about" && (
                <div className="flex flex-col gap-4 absolute bg-[#011936] z-20 w-[145px] ">
                  <Link
                    to="/about"
                    onClick={() => {
                      toggleMenu("");
                      setShowMenu(!showMenu);
                    }}
                  >
                    <div className="text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                      About Operose
                    </div>
                  </Link>
                  <Link
                    to="/people"
                    onClick={() => {
                      toggleMenu("");
                      setShowMenu(!showMenu);
                    }}
                  >
                    <div className="text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                      People
                    </div>
                  </Link>
                  <Link
                    to="/why"
                    onClick={() => {
                      toggleMenu("");
                      setShowMenu(!showMenu);
                    }}
                  >
                    <div className="text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                      Why
                    </div>
                  </Link>
                </div>
              )}
            </div>
            <div className="relative ">
              <Link
                to="#"
                className={`flex items-center py-1 pl-2  text-white  hover:text-[#6cc551] h-11 ${
                  pathname === "/Products" &&
                  "h-11 flex  items-start  text-[#6cc551]"
                }`}
                onClick={() => toggleMenu("Products")}
              >
                <b className="text-[14px]">CATAGORIES</b>
                <RiArrowDropDownLine className="text-3xl" />
              </Link>

              {expandedMenu === "Products" && (
                <div className="flex flex-col gap-3 absolute bg-[#011936] z-20 w-[145px]">
                  {categories.map((index) => (
                    <div key={index.id}>
                      <Link
                        to={`/product/${index.category_name}`}
                        onClick={() => {
                          toggleMenu("");
                          setShowMenu(!showMenu);
                        }}
                      >
                        <h1 className=" text-white hover:text-[#6cc551] px-2 py-2 font-semibold">
                          {index.category_name}
                        </h1>
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="relative">
              <Link
                to="#"
                className={`flex justify-center items-center py-1 pl-2  text-white hover:text-[#6cc551] h-11 ${
                  pathname === "/sales" &&
                  "h-11 flex  items-start text-[#6cc551]"
                } `}
                onClick={() => toggleMenu("sales")}
              >
                <b className="text-[14px]">SALES SUPPORT</b>
                <RiArrowDropDownLine className="text-3xl" />
              </Link>

              {expandedMenu === "sales" && (
                <div className="flex flex-col gap-4 absolute bg-[#011936] z-20 w-[145px]">
                  <Link
                    to="/business-query"
                    onClick={() => {
                      toggleMenu("");
                      setShowMenu(!showMenu);
                    }}
                  >
                    <div className="text-white hover:text-[#6cc551] px-2 py-1 pl-2font-semibold">
                      Business Query
                    </div>
                  </Link>
                </div>
              )}
            </div>

            <Link
              to="/gallery"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` flex justify-start items-center py-1 px-4 pl-2 text-white hover:text-[#6cc551] h-11 ${
                pathname === "/gallery" && " text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">GALLERY</b>
            </Link>

            {/* <Link
              to="/people"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` h-11 flex justify-start items-center py-1 px-4 pl-2 text-white hover:text-[#6cc551] h-11${
                pathname === "/people" && " text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">PEOPLE</b>
            </Link> */}
            <Link
              to="/updatess"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` flex justify-start items-center py-1  pl-2 text-white hover:text-[#6cc551] h-11 ${
                pathname === "/updatess" &&
                "h-11 flex justify-start items-start text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">UPDATES</b>
            </Link>
            <Link
              to="/catalogues"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` flex justify-start items-center py-1  pl-2 text-white hover:text-[#6cc551] h-11 ${
                pathname === "/catalogues" &&
                "  h-11 flex justify-start items-start  text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">CATALOGUE</b>
            </Link>

            <Link
              to="/faq"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` flex justify-start items-center py-1  pl-2 text-white hover:text-[#6cc551] h-11 ${
                pathname === "/faq" &&
                "h-11 flex justify-start items-start  text-[#6cc551]"
              } `}
            >
              <b className="text-[14px]">FAQ's</b>
            </Link>

            <Link
              to="/contact"
              onClick={() => {
                toggleMenu("");
                setShowMenu(!showMenu);
              }}
              className={` flex justify-start items-center py-1  pl-2 text-white hover:text-[#6cc551] h-11 ${
                pathname === "/contact" &&
                "h-11 flex justify-start items-start text-[#6cc551] "
              }`}
            >
              <b className="text-[14px]">CONTACT US</b>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Nav;
