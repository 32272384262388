import React, { useEffect, useState } from "react";
import { FaFileDownload } from "react-icons/fa";

const downloadPdf = () => {
  const pdfPath = "/dumy_pdf.pdf";

  const link = document.createElement("a");

  // Set its href attribute to the path of the PDF
  link.href = pdfPath;

  // Set the download attribute to force download the file
  link.setAttribute("download", "example.pdf");

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click event on the link
  link.click();

  // Clean up: remove the link from the DOM
  document.body.removeChild(link);
};

const About = () => {
  const [Catalogue, setCatalogue] = useState("");
  const [Cata, setCata] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="min-h-[80vh] m-auto">
        <div
          className="bg-cover bg-center h-44 flex justify-center items-center"
          style={{
            backgroundImage: 'url("footer.png")',
          }}
        >
          <div className="text-2xl text-[#ffff00] pl-6">
            WE ARE TRADING PEOPLE WITH MODERN THOUGHTS
          </div>
        </div>

        <div className="flex w-[91%] sm:w-[80%]  m-auto  flex-wrap md:flex-nowrap">
          <div
            className="shadow-2xl w-[100%] h-[123px] mx-4 my-10 md:my-0 rounded-md py-5 px-5"
            onMouseEnter={() => setCata("product")}
            onMouseLeave={() => setCata("")}
          >
            <FaFileDownload
              // className=" group-hover:bg-orange-400 group-hover:text-white shadow-lg  w-[52px] h-[45px] rounded-md flex items-center justify-center p-2 -mt-10"
              // onClick={downloadPdf}
              className={`${
                Cata === "product" ? "bg-[#ff7800] text-white" : "bg-white"
              }  w-[52px] h-[45px] rounded-md flex shadow-lg items-center justify-center p-2 -mt-10 cursor-pointer`}
              // onClick={downloadPdf}
            />
            <h1 className="mt-2 text-lg sm:text-md lg:text-2xl">
              Download Company Catalogue
            </h1>
            <button
              className="pt-3 text-gray-600 text-md sm:text-md"
              // onClick={downloadPdf}
            >
              Click Here
            </button>
          </div>

          <div
            className="shadow-2xl w-[100%] h-[123px] mx-4 my-10 md:my-0 rounded-md py-5 px-5"
            onMouseEnter={() => setCatalogue("product")}
            onMouseLeave={() => setCatalogue("")}
          >
            <FaFileDownload
              className={`${
                Catalogue === "product" ? "bg-[#ff7800] text-white" : "bg-white"
              }  w-[52px] h-[45px] rounded-md flex shadow-lg items-center justify-center p-2 -mt-10 cursor-pointer`}
              // onClick={downloadPdf}
            />
            <h1 className="mt-2 text-lg sm:text-md lg:text-2xl">
              Download Product Catalogue
            </h1>
            <button
              className="pt-3 text-gray-600 text-md sm:text-md"
              // onClick={downloadPdf}
            >
              Click Here
            </button>
          </div>
        </div>
        <div className="sm:w-[76%] w-[80%] m-auto ">
          <h1 className="text-2xl font-semibold mt-10 mb-5 ">About Us</h1>
          <div className="m-auto mb-2">
            <p className="text-justify p-3">
              Operose is a leading export-import company with a strong focus on
              agriculture and herbal product sourcing from tribes and farmers.
              We have collaborated with numerous tribes in many villages, where
              we directly purchase forest produce from the local communities
              without any middleman. We believe in providing the right price for
              their products and ensuring that these communities benefit from
              our business operations.
            </p>
          </div>

          <div className="m-auto mb-2">
            <p className="text-justify p-3">
              At Operose, we have our processing factory and a reliable network
              of vendors to ensure that our products meet the highest quality
              standards. We export various types of agricultural products,
              spices, and minor forest produce, which not only contribute to our
              company's growth but also help in increasing the country's foreign
              exchange reserves. Our company is dedicated to supporting the
              growth of our nation's economy by contributing to its growth.
            </p>
          </div>
          <div className="m-auto mb-2">
            <p className="text-justify p-3">
              In addition to our business operations, we also focus on providing
              sustainable livelihood options for tribes and farmers by
              distributing various types of costumes that help them collect
              products from the forest. We understand the importance of
              supporting these communities in their daily livelihood and strive
              to make a positive impact in their lives.
            </p>
          </div>
          <div className="m-auto mb-5">
            <p className="text-justify p-3">
              Our commitment to ethical business practices and sustainable
              sourcing has made us a reliable partner for many tribes and
              farmers. We take pride in working with local communities and
              contributing to their growth and well-being. At Operose, we are
              dedicated to achieving our business goals while also making a
              positive impact on the world around us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
