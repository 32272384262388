import React, { useEffect } from "react";
import Product from "../Components/Products";

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" ">
      <div className="m-a">
        <Product />
      </div>
    </div>
  );
};

export default Products;
